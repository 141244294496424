<template>
  <div>
    <Modal v-model="show" :width="800">
      <p slot="header" class="text-center">资产详情</p>
      <h4 class="workplatform-title m-t-10 m-b-10">基础信息</h4>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">编号</label><span>{{ assetInfo.code }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">资产名称</label><span>{{ assetInfo.assetName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">所属类型</label>
          <span>{{ assetInfo.deviceBigmodelName + ' - ' + assetInfo.devicemodelName }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">租赁类型</label>
          <span>{{ assetInfo.openLease ? '开放租赁' : '自用' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">位置</label><span>{{ assetInfo.positionName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">出口</label><span>{{ assetInfo.exits }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">楼层</label>
          <span>{{ assetInfo.floor }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">面积</label><span>{{ assetInfo.area + ' ㎡' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">长x宽x高</label>
          <span>{{ assetInfo.width + 'mm x ' + assetInfo.height + 'mm x ' + assetInfo.depth + 'mm' }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">状态</label>
          <span>{{ assetInfo.enabledName }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">通电</label>
          <span>{{ assetInfo.electric ? '是' : '否' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">通水</label>
          <span>{{ assetInfo.waterSupply === 1 ? '是' : '否' }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">通气</label>
          <span>{{ assetInfo.gasSupply === 1 ? '是' : '否' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">消防验收</label>
          <span>{{ assetInfo.gasSupply ? '是' : '否' }}</span>
        </i-col>
        <i-col span="8">
          <label class="title">管理单位</label>
          <span>{{ assetInfo.manageUnitName }}</span>
        </i-col>
      </Row>
      <Row :gutter="8" class="p-b-10">
        <i-col span="8">
          <label class="title">创建时间</label>
          <span>{{ assetInfo.createTime }}</span>
        </i-col>
      </Row>
    </Modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      assetInfo: {}
    }
  },
  methods: {
    showModal (obj) {
      this.assetInfo = obj
      this.show = true
    }
  }
}
</script>
