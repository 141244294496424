<template>
  <div>
    <Modal v-model="show">
      <p slot="header" class="text-center">文件导出</p>
      <div class="m-b-10">
        <Select v-model="assetIds" size="small" multiple placeholder="选择资产（可多选）">
          <Option v-for="item in assetArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </div>
      <div>
        <Select v-model="deviceBigModels" multiple size="small" placeholder="选择资产大类（可多选，不选择默认为所有）">
          <Option v-for="item in bigModelArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </div>

      <div slot="footer" class="text-right">
        <Button type="text" class="m-r-5" @click="show = false">取消</Button>
        <Button type="primary" :loading="exportsLoading" :disabled="assetIds.length === 0" @click="handleExports">{{
          exportsLoading ? '数据生成中...' : '确定' }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { downloadFileRequest } from '@/utils/download'
import { startExportDevice } from '@/api/rim/device'
export default {
  data () {
    return {
      show: false,
      assetIds: [],
      deviceBigModels: [],
      exportsLoading: false,
      sign: ''
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.comAsset.assetArray
    },
    bigModelArray () {
      return this.$store.state.comAsset.bigModelArray
    }
  },
  methods: {
    showModal () {
      this.assetIds = []
      this.deviceBigModels = []
      this.show = true
    },
    handleExports () {
      this.sign = ''
      this.exportsLoading = true
      this.fileDownload()
    },
    fileDownload () {
      const deviceBigModelIds = this.deviceBigModels.length ? this.deviceBigModels : this.bigModelArray.map(x => x.id)
      startExportDevice({ assetIds: JSON.stringify(this.assetIds), sign: this.sign, deviceBigModels: JSON.stringify(deviceBigModelIds) }).then(res => {
        if (res) {
          this.sign = res.sign
          if (res.status === 2) {
            const filename = '资产列表.xls'
            downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-rim/v1/device/downloadexcel', { sign: this.sign }, filename)
            this.exportsLoading = false
          } else {
            setTimeout(() => {
              this.fileDownload()
            }, 3000)
          }
        } else {
          this.exportsLoading = false
        }
      }).catch((_err) => {
        this.exportsLoading = false
      })
    }
  }
}
</script>
