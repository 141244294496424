<template>
  <div>
    <Modal v-model="show" width="1000">
      <p slot="header" class="text-center">租赁记录</p>
      <div>
        <div class="text-right m-b-10">
          <Button type="success" size="small" @click="handleAdd">新建记录</Button>
        </div>
        <Table stripe size="small" :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
      </div>
    </Modal>
    <Modal v-model="addRecordModal" footer-hide>
      <p slot="header" class="text-center">新建租赁记录</p>
      <Form ref="formData" :model="formData" :rules="formRules" label-position="top">
        <FormItem label="租户" prop="advertiserId">
          <Select v-model="formData.advertiserId" size="small" placeholder="通过关键字搜索查找租户" filterable
            :remote-method="handleSearchTenant" :loading="searchLoading">
            <Option v-for="(option, index) in tenantArray" :value="option.id" :key="index">{{ option.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="业态" prop="industryId">
          <Select v-model="formData.industryId" size="small" placeholder="请选择业态">
            <Option v-for="(option, index) in industryArray" :value="option.id" :key="index">{{ option.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="租赁周期">
          <Row :gutter="8">
            <i-col span="11">
              <FormItem prop="startDate">
                <DatePicker v-model="formData.startDate" size="small" type="date" placeholder="选择开始日期"
                  style="width: 100%"></DatePicker>
              </FormItem>
            </i-col>
            <i-col span="2" class="text-center">至</i-col>
            <i-col span="11">
              <FormItem prop="endDate">
                <DatePicker v-model="formData.endDate" size="small" type="date" placeholder="选择结束日期" style="width: 100%">
                </DatePicker>
              </FormItem>
            </i-col>
          </Row>
        </FormItem>
        <FormItem class="text-right m-t-20">
          <Button type="text" class="m-r-5" @click="addRecordModal = false">取消</Button>
          <Button type="primary" :loading="submitLoading" @click="handleSubmit">确认录入</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { getLeaseList, addLease, disableLease } from '@/api/rim/device'
import { getAdvertiserPage } from '@/api/crm/advertiser'
import { getRootIndustryList } from '@/api/os/industry'
import { ParseDate } from '@/utils/dateFormat'
export default {
  porps: {
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      userInfo: this.$store.getters.token.userInfo,
      show: false,
      tableLoading: false,
      curDeviceInfo: {},
      list: [],
      tableColumns: [
        { title: '租户名称', tooltip: true, ellipsis: true, minWidth: 100, key: 'advertiserName' },
        { title: '业态', align: 'center', key: 'industryName' },
        {
          title: '租赁周期',
          align: 'center',
          minWidth: 140,
          key: 'startDate',
          render: (h, { row }) => {
            return h('span', `${row.startDate} 至 ${row.endDate}`)
          }
        },
        { title: '记录时间', align: 'center', minWidth: 120, key: 'createTime' },
        { title: '记录人', align: 'center', key: 'createUserName' },
        {
          title: '是否已作废',
          align: 'center',
          key: 'enabled',
          render: (h, { row }) => {
            return h('span', row.enabled ? '否' : '是')
          }
        }, {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            return h('div', [
              row.enabled ? h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleCancel(row)
                  }
                }
              }, '作废') : h('span', '/')
            ])
          }
        }
      ],
      addRecordModal: false,
      formData: {
        advertiserId: null,
        advertiserName: '',
        deviceId: null,
        industryId: null,
        industryName: '',
        startDate: '',
        endDate: ''
      },
      formRules: {
        advertiserId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        industryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        startDate: [
          { required: true, type: 'date', message: ' ', trigger: 'change' }
        ],
        endDate: [
          { required: true, type: 'date', message: ' ', trigger: 'change' }
        ]
      },
      searchLoading: false,
      tenantArray: [],
      industryArray: [],
      submitLoading: false
    }
  },
  methods: {
    showModal (params) {
      this.curDeviceInfo = params
      this.formData.deviceId = this.curDeviceInfo.id
      this.getRecordsList()
      this.show = true
    },
    getRecordsList () {
      getLeaseList({ deviceId: this.curDeviceInfo.id }).then(res => {
        if (res && !res.errcode) {
          this.list = res
        } else {
          this.list = []
        }
      })
    },
    handleAdd () {
      this.formData.advertiserId = null
      this.formData.advertiserName = ''
      this.formData.industryId = null
      this.formData.industryName = ''
      this.formData.startDate = ''
      this.formData.endDate = ''
      this.addRecordModal = true
      this.getIndustryData()
    },
    getIndustryData () {
      getRootIndustryList({ publisherId: this.userInfo.publisherId, type: 3 }).then(res => {
        if (res && !res.errcode) {
          this.industryArray = res
        } else {
          this.industryArray = []
        }
      })
    },
    handleSearchTenant (keyword) {
      const q = {
        pageSize: 100,
        pageNumber: 1,
        sign: 2,
        type: null,
        publisherId: this.userInfo.publisherId,
        keyword: keyword
      }
      this.searchLoading = true
      getAdvertiserPage(q).then(res => {
        if (res && !res.errcode) {
          this.tenantArray = res.list
        } else {
          this.tenantArray = res.list
        }
      }).finally(() => { this.searchLoading = false })
    },
    handleSubmit () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.formData.advertiserName = this.tenantArray.find(x => x.id === this.formData.advertiserId).name
          this.formData.industryName = this.industryArray.find(x => x.id === this.formData.industryId).name
          this.formData.startDate = ParseDate(this.formData.startDate)
          this.formData.endDate = ParseDate(this.formData.endDate)

          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误并提交？',
            onOk: () => {
              addLease(this.formData).then(res => {
                if (res) {
                  this.$Notice.success({ title: '操作提示', desc: '提交成功' })
                  this.addRecordModal = false
                  this.getRecordsList()
                  this.onSuccess()
                }
              })
            }
          })
        }
      })
    },
    /**
     * 作废
     * @param {Object} param
     */
    handleCancel (param) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要作废当前数据？',
        onOk: () => {
          disableLease({ deviceLeaseId: param.id }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getRecordsList()
            }
          })
        }
      })
    }
  }
}
</script>
