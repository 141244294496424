<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select size="small" placeholder="所属线路" v-model="query.assetId" @on-change="handleChangeAsset">
          <i-option v-for="item in assetArray" :key="'asset_' + item.id" :value="item.id">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <!-- <i-col :span="3">
        <Select size="small" placeholder="资产大类" v-model="query.deviceBigmodel" @on-change="handleChangeBigmodel">
          <i-option v-for="item in parentDeviceModelArray" :key="'bigmodel_' + item.id" :value="item.id">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col> -->
      <i-col span="3">
        <Select size="small" placeholder="资产类型" v-model="query.devicemodel" clearable>
          <i-option v-for="item in childDeviceModelArray" :key="'smallmodel_' + item.id" :value="item.id">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" placeholder="站点" v-model="query.stationId" clearable>
          <i-option v-for="item in stationArray" :key="'qStation_' + item.id" :value="item.id">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" placeholder="状态" v-model="query.enabled" clearable>
          <i-option v-for="item in statusArray" :key="'status_' + item.id" :value="item.id">
            {{ item.name }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" placeholder="租赁类型" v-model="query.openLease" clearable>
          <i-option :value="1"> 开放租赁</i-option>
          <i-option :value="0"> 自用</i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker size="small" v-model="saleDate" type="daterange" split-panels placeholder="选择租赁时间段"></DatePicker>
      </i-col>
      <i-col span="4">
        <i-input v-model="query.keyword" size="small" placeholder="关键字：租户名称/业态名称"></i-input>
      </i-col>
      <i-col span="2">
        <Button type="primary" icon="ios-search" size="small" @click="handleSearch">搜索</Button>
      </i-col>
    </Row>
    <Row class="m-b-10">
      <i-col span="12"></i-col>
      <i-col span="12" class="text-right">
        <i-button size="small" type="success" class="m-r-5" @click="handleAddAsset">
          新建资产
        </i-button>
        <!-- <i-button size="small" type="success" @click="handleImport">导入</i-button> -->
        <i-button size="small" type="primary" @click="handleExport">导出</i-button>
      </i-col>
    </Row>
    <Table stripe size="small" :data="tableData.list" :columns="tableColumns" :loading="tableLoading">
    </Table>
    <div class="paging_style">
      <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-elevator show-sizer
        :current="query.pageNumber" @on-change="handleChangePage" :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'></Page>
    </div>
    <Modify ref="modify" :assetArray="assetArray" :optionType="optionType" :onSuccess="initData" />
    <Detail ref="detail" />
    <SaleRecords ref="saleRecords" :onSuccess="initData" />
    <FileExports ref="fileExports" />
  </div>
</template>
<script>
import { getMaintenanceAssetList, getExistDeviceModelList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'
import { getStatusList, getEstateDevicePage2 } from '@/api/rim/device'
import { ParseDate } from '@/utils/dateFormat'

import Modify from './Modify.vue'
import Detail from './Detail.vue'
import SaleRecords from './SaleRecords.vue'
import FileExports from './FileExports.vue'
export default {
  components: {
    Modify,
    Detail,
    SaleRecords,
    FileExports
  },
  data () {
    return {
      optionType: 1,
      query: {
        pageNumber: 1,
        pageSize: 15,
        assetId: undefined,
        deviceBigmodel: 3,
        devicemodel: undefined,
        enabled: undefined,
        stationId: undefined,
        openLease: undefined,
        leaseStart: '',
        leaseEnd: '',
        keyword: ''
      },
      saleDate: [],
      pagesizeSetting: [15, 50, 100, 200, 500],
      assetArray: [],
      parentDeviceModelArray: [],
      childDeviceModelArray: [],
      stationArray: [],
      statusArray: [],
      tableLoading: false,
      tableData: {
        list: [],
        totalRow: 0
      },
      tableColumns: [
        { title: '编号', key: 'code' },
        { title: '类型', align: 'center', key: 'devicemodelName' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '出口', align: 'center', key: 'exits' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '位置', align: 'center', key: 'positionName' },
        { title: '面积（㎡）', align: 'center', key: 'area' },
        { title: '状态', align: 'center', key: 'enabledName' },
        { title: '经营方', align: 'center', ellipsis: true, tooltip: true, key: 'manageUnitName' },
        {
          title: '租赁类型',
          align: 'center',
          key: 'openLease',
          render: (h, params) => {
            return h('span', params.row.openLease ? '开放租赁' : '自用')
          }
        },
        {
          title: '通电',
          align: 'center',
          key: 'electric',
          render: (h, params) => {
            return h('span', params.row.electric ? '是' : '否')
          }
        },
        {
          title: '通水',
          align: 'center',
          key: 'waterSupply',
          render: (h, params) => {
            return h('span', params.row.waterSupply ? '是' : '否')
          }
        },
        {
          title: '通气',
          align: 'center',
          key: 'gasSupply',
          render: (h, params) => {
            return h('span', params.row.gasSupply ? '是' : '否')
          }
        },
        {
          title: '消防验收',
          align: 'center',
          key: 'fireApproval',
          render: (h, params) => {
            return h('span', params.row.fireApproval ? '是' : '否')
          }
        },
        {
          title: '当前租赁信息',
          align: 'center',
          width: 160,
          render: (h, { row }) => {
            return h('p', row.advertiserName ? `${row.advertiserName}(${row.startDate}至${row.endDate})` : '/')
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleEidt(params.row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDetail(params.row)
                  }
                }
              }, '详情'),
              h('a', {
                on: {
                  click: () => {
                    this.handleSaleRecords(params.row)
                  }
                }
              }, '租赁信息')
            ])
          }
        }
      ]
    }
  },
  created () {
    this.getAssetData()
    this.getStatusData()
  },
  methods: {
    initData () {
      this.getTableData()
      this.$store.dispatch('getDeviceModelStaData', { assetId: this.query.assetId, deviceBigmodel: this.query.deviceBigmodel })
      this.$store.dispatch('getDeviceStatusStaData', { assetId: this.query.assetId, deviceBigmodel: this.query.deviceBigmodel })
    },
    getTableData () {
      this.tableLoading = true
      this.query.leaseStart = this.saleDate.length ? (this.saleDate[0] !== '' ? ParseDate(this.saleDate[0]) : '') : ''
      this.query.leaseEnd = this.saleDate.length ? (this.saleDate[1] !== '' ? ParseDate(this.saleDate[1]) : '') : ''

      getEstateDevicePage2(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = res
        } else {
          this.tableData.list = []
          this.tableData.totalRow = 0
        }
      }).finally(() => { this.tableLoading = false })
    },
    getAssetData () {
      getMaintenanceAssetList().then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
          this.query.assetId = this.assetArray.length ? this.assetArray[0].id : undefined
          this.handleChangeAsset()
        } else {
          this.assetArray = []
        }
        this.$store.commit('set_asset_array', this.assetArray)
      })
    },
    handleChangeAsset () {
      this.$store.commit('set_cur_assetId', this.query.assetId)
      this.getBigModelData()
      this.getStationData()
    },
    getBigModelData () {
      getExistDeviceModelList({ assetId: this.query.assetId, deviceBigModel: this.query.deviceBigmodel }).then(res => {
        if (res && !res.errcode) {
          this.parentDeviceModelArray = res
          this.$store.commit('set_big_model_array', this.parentDeviceModelArray)
          // this.query.deviceBigmodel = this.parentDeviceModelArray.length ? this.parentDeviceModelArray[0].id : undefined
          this.handleChangeBigmodel()
          this.initData()
        } else {
          this.parentDeviceModelArray = []
        }
      })
    },
    handleChangeBigmodel () {
      const result = this.parentDeviceModelArray.find(x => x.id === this.query.deviceBigmodel)
      this.childDeviceModelArray = result && result.children ? result.children : []
    },
    getStationData () {
      getStationByAssetId({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getStatusData () {
      getStatusList().then(res => {
        if (res && !res.errcode) {
          this.statusArray = res
        } else {
          this.statusArray = []
        }
        this.$store.commit('set_status_array', this.statusArray)
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTableData()
    },
    handleExport () {
      this.$refs.fileExports.showModal()
    },
    handleAddAsset () {
      this.$refs.modify.showModal()
    },
    handleEidt (params) {
      this.optionType = 2
      this.$store.commit('set_cur_device_info', params)
      this.$refs.modify.showModal()
    },
    handleViewDetail (params) {
      this.$refs.detail.showModal(params)
    },
    handleSaleRecords (params) {
      this.$refs.saleRecords.showModal(params)
    }
  }
}
</script>
