import request from '@/utils/requestV2'
const qs = require('qs')

// 获取客户列表
export function getAdvertiserPage (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getadvertiserpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取可用客户列表
export function getAvailableAdvertiserPage (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getavailableadvertiserpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 媒介主新增公益客户
export function addCommunityAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/addcommunityAdvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取媒介主下的公益客户
export function getCommunityAdvertiserPage (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getcommunityadvertiserpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
export function getContactList (query) {
  return request({
    url: '/ooh-crm/v1/advertiser/getcontactlist',
    method: 'post',
    data: qs.stringify(query)
  })
}
export function addBySeller (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/addbyseller',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function addbypublisher (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/addbypublisher',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function addContact (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/addcontact',
    method: 'post',
    data: qs.stringify(data)
  })
}
export function getAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取客户上传附件集合
export function getFileList (data) {
  return request({
    url: '/ooh-crm/v1/advertiserfile/getfilelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取客户历史纪录
export function getAdvertiserHistoryList (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getadvertiserhistorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
export function deleteContact (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/deletecontact',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 媒介主无效化客户
export function invalidAdvertiser (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/invalidadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取客户相关字典
export function getadvertiserdict (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getadvertiserdict',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取二类客户列表
export function getcommercepage (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/getcommercepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 更新广告客户
export function updateadvertiser (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/updateadvertiser',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 新增商业客户
export function addBusiness (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/addbusiness',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 撤回审批
export function stoptask (data) {
  return request({
    url: '/ooh-crm/v1/advertiser/stoptask',
    method: 'post',
    data: qs.stringify(data)
  })
}
