<template>
  <div>
    <Modal v-model="modifyShow" width="800" footer-hide>
      <p slot="header" class="text-center">{{ optionType === 1 ? '新增商业资产' : '编辑商业资产' }}</p>
      <Form ref="formData" :model="formData" :rules="formRules" label-position="top">
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="编号" prop="code">
              <i-input size="small" v-model="formData.code" placeholder="编号" />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="所属线路" prop="assetId">
              <Select size="small" placeholder="所属线路" v-model="formData.assetId" @on-change="handleChangeAsset">
                <i-option v-for="item in assetArray" :key="'asset_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <!-- <i-col span="8">
            <FormItem label="资产大类" prop="deviceBigmodel">
              <Select size="small" placeholder="资产大类" :disabled="!formData.assetId" v-model="formData.deviceBigmodel">
                <i-option v-for="item in parentDeviceModelArray" :key="'bigmodel_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col> -->
          <i-col span="8">
            <FormItem label="资产类型" prop="devicemodel">
              <Select size="small" placeholder="资产类型" :disabled="!formData.assetId" v-model="formData.devicemodel">
                <i-option v-for="item in childDeviceModelArray" :key="'sModel_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="所属站点" prop="stationId">
              <Select size="small" placeholder="所属站点" :disabled="!formData.assetId" v-model="formData.stationId">
                <i-option v-for="item in stationArray" :key="'station_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="所属位置" prop="position">
              <Select size="small" placeholder="所属位置" v-model="formData.position">
                <i-option v-for="item in positionArray" :key="'position_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="所属楼层" prop="floorId">
              <Select size="small" placeholder="所属楼层" v-model="formData.floorId">
                <i-option v-for="item in floorArray" :key="'floor_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="管理单位" prop="manageUnit">
              <Select size="small" placeholder="管理单位" v-model="formData.manageUnit">
                <i-option v-for="item in manageCompanyArray" :key="'com_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="出口">
              <i-input size="small" v-model="formData.exits" placeholder="出口" />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="面积（㎡）">
              <InputNumber size="small" :max="99999" :min="0" v-model="formData.area" style="width:100%" placeholder="面积">
              </InputNumber>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="长度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.width" style="width:100%"
                placeholder="长度"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="宽度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.height" style="width:100%"
                placeholder="宽度"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="高度（mm）">
              <InputNumber size="small" :max="999999" :min="0" v-model="formData.depth" style="width:100%"
                placeholder="高度"></InputNumber>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="通电">
              <Select size="small" placeholder="通电" v-model="formData.electric">
                <i-option v-for="item in trueFalseEnum" :key="'electric_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="通水">
              <Select size="small" placeholder="通水" v-model="formData.waterSupply">
                <i-option v-for="item in trueFalseEnum" :key="'waterSupply_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="通气">
              <Select size="small" placeholder="通气" v-model="formData.gasSupply">
                <i-option v-for="item in trueFalseEnum" :key="'gasSupply_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="8">
          <i-col span="8">
            <FormItem label="消防验收">
              <Select size="small" placeholder="消防验收" v-model="formData.fireApproval">
                <i-option v-for="item in trueFalseEnum" :key="'fireApproval_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="租赁类型">
              <Select size="small" placeholder="租赁类型" v-model="formData.openLease">
                <i-option :value="1">开放租赁</i-option>
                <i-option :value="0">自用</i-option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="状态">
              <Select size="small" placeholder="状态" v-model="formData.enabled">
                <i-option v-for="item in statusArray" :key="'status_' + item.id" :value="item.id">
                  {{ item.name }}
                </i-option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <FormItem class="text-right m-t-20">
          <Button type="text" class="m-r-5" @click="handleCancel">取消</Button>
          <Button type="primary" :loading="submitLoading" @click="handleSubmit">确认录入</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import { getDeviceModelList, getPositionList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'
import { getCompanyList } from '@/api/os/company'
import { submit } from '@/api/rim/device'
// import { getFloor } from '@/api/rim/device'
const getFomrData = () => {
  return {
    area: 0,
    assetId: null,
    certNo: '未办理',
    code: '',
    depth: 0,
    deviceBigmodel: 3,
    devicemodel: null,
    electric: 1,
    enabled: 1,
    exits: '',
    facilities: [],
    fault: false,
    fireApproval: 1,
    floorId: undefined,
    gasSupply: 1,
    height: 0,
    id: undefined,
    innerCode: '',
    manageUnit: null,
    model: '',
    name: '',
    orientation: '',
    position: null,
    publisherId: 0,
    quantity: 0,
    remark: '',
    street: '',
    stationId: undefined,
    unit: '',
    uses: '',
    usingUnit: 0,
    waterSupply: 1,
    width: 0,
    openLease: 1
  }
}
export default {
  props: {
    optionType: {
      type: Number,
      default: 1 // 1：新增，2：编辑
    },
    assetArray: [Array],
    // curDeviceInfo: [Object],
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      modifyShow: false,
      parentDeviceModelArray: [],
      childDeviceModelArray: [],
      stationArray: [],
      floorArray: [
        { id: -4, name: '地下四层' },
        { id: -3, name: '地下三层' },
        { id: -2, name: '地下二层' },
        { id: -1, name: '地下一层' },
        { id: 1, name: '地上一层' },
        { id: 2, name: '地上二层' },
        { id: 3, name: '地上三层' },
        { id: 4, name: '地上四层' }
      ],
      trueFalseEnum: [
        { id: 1, name: '是' },
        { id: 0, name: '否' }
      ],
      positionArray: [],
      manageCompanyArray: [],
      formData: getFomrData(),
      formRules: {
        code: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        assetId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        devicemodel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        stationId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        position: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        floorId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        manageUnit: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      },
      submitLoading: false
    }
  },
  computed: {
    statusArray () {
      return this.$store.state.comAsset.statusArray
    },
    curDeviceInfo () {
      return this.$store.state.comAsset.curDeviceInfo
    }
  },
  methods: {
    showModal () {
      // 重置数据
      Object.assign(this.formData, getFomrData())
      this.getCompanyData()
      if (this.optionType === 1) {
        this.getPositionData()
      }
      this.modifyShow = true
    },
    handleChangeAsset () {
      // this.formData.position = null
      this.getBigModelData()
      this.getStationData()
      this.getPositionData()
    },
    getBigModelData () {
      if (!this.formData.assetId) {
        return false
      }
      getDeviceModelList({ assetId: this.formData.assetId, deviceBigModel: this.formData.deviceBigModel }).then(res => {
        if (res && !res.errcode) {
          this.parentDeviceModelArray = res
          // this.formData.deviceBigmodel = this.parentDeviceModelArray.length ? this.parentDeviceModelArray[0].id : undefined
          this.handleChangeBigmodel()
        } else {
          this.parentDeviceModelArray = []
        }
      })
    },
    handleChangeBigmodel () {
      const result = this.parentDeviceModelArray.find(x => x.id === this.formData.deviceBigmodel)
      this.childDeviceModelArray = result && result.children ? result.children : []
    },
    getStationData () {
      getStationByAssetId({ assetId: this.formData.assetId }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    // handleChangeStation () {
    //   this.getFloorData()
    // },
    // getFloorData () {
    //   getFloor({ stationId: this.formData.stationId }).then(res => {
    //     if (res && !res.errcode) {
    //       this.floorArray = res
    //     } else {
    //       this.floorArray = []
    //     }
    //   })
    // }
    getPositionData () {
      const assetIds = !this.formData.assetId ? this.assetArray.map(x => x.id) : [this.formData.assetId]
      getPositionList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getCompanyData () {
      const params = { enabled: true, publisherId: this.$store.getters.token.userInfo.publisherId }
      getCompanyList(params).then(res => {
        if (res && !res.errcode) {
          this.manageCompanyArray = res
        } else {
          this.manageCompanyArray = []
        }
      })
    },
    handleCancel () {
      this.modifyShow = false
      this.$refs.formData.resetFields()
      this.$store.commit('set_cur_device_info', {})
      // this.formData = getFomrData()
    },
    handleSubmit () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误并提交？',
            onOk: async () => {
              this.submitLoading = true
              const postData = Object.assign({}, this.formData)
              postData.facilities = this.formData.facilities.toString()
              postData.uses = this.formData.uses

              const result = await submit(JSON.stringify(postData)).finally(() => {
                this.submitLoading = false
                this.modifyShow = false
              })
              if (result && result.errcode === 0) {
                this.$Notice.success({ title: '操作提示', desc: '提交成功' })
                this.onSuccess()
              } else {
                this.$Notice.error({ title: '操作提示', desc: '提交失败' })
              }
            }
          })
        }
      })
    }
  },
  watch: {
    curDeviceInfo: {
      deep: true,
      immediate: true,
      handler (newval, oldVal) {
        if (this.optionType === 2 && Object.keys(newval).length) {
          Object.keys(this.formData).forEach(key => {
            this.formData[key] = this.curDeviceInfo[key]
          })
          this.formData.floorId = this.curDeviceInfo.floorEntity.floor
          this.formData.electric = Number(this.curDeviceInfo.electric)
          this.formData.openLease = Number(this.curDeviceInfo.openLease)
          this.formData.facilities = this.curDeviceInfo.facilities.split(',')
          this.formData.uses = this.curDeviceInfo.uses
          this.formData.orientation = this.curDeviceInfo.orientation.toString()
          this.handleChangeAsset()
        }
      }
    }
  }
}
</script>
