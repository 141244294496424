import request from '@/utils/requestV2'
const qs = require('qs')

export function getRootIndustryList (data) {
  return request({
    url: '/ooh-os/v1/industry/getrootindustrylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 新增行业
 * @param {Object} data
 * @returns
 */
export function add (data) {
  return request({
    url: '/ooh-os/v1/industry/add',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 编辑行业
 * @param {Object} data
 * @returns
 */
export function update (data) {
  return request({
    url: '/ooh-os/v1/industry/update',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 删除行业
 * @param {Object} data
 * @returns
 */
export function deleteIndustry (data) {
  return request({
    url: '/ooh-os/v1/industry/delete',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取行业分页
 * @param {Object} data
 * @returns
 */
export function page (data) {
  return request({
    url: '/ooh-os/v1/industry/page',
    method: 'post',
    data: qs.stringify(data)
  })
}
